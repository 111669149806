<template>
  <div class="register container mt-5">
    <div class="row justify-content-center">
      <div >
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid mb-4">
        <form @submit.prevent="handleRegister" class="row">
          <div class="mb-3 col-md-6">
            <label for="firstName" class="form-label">First Name</label>
            <input type="text" id="firstName" v-model="firstName" @blur="touched.firstName = true" class="form-control" required />
            <small v-if="touched.firstName && !firstName" class="text-danger">First Name is required.</small>
          
          </div>
          <div class="mb-3 col-md-6">
            <label for="lastName" class="form-label">Last Name</label>
            <input type="text" id="lastName" v-model="lastName" @blur="touched.lastName = true" class="form-control" required />
            <small v-if="touched.lastName && !lastName" class="text-danger">Last Name is required.</small>
          </div>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" id="email" v-model="email"  @blur="touched.email = true" class="form-control" required />
            <small v-if="touched.email && !email" class="text-danger">Email is required.</small>
            <small v-else-if="touched.email && !isEmailValid" class="text-danger">Please enter a valid email address.</small>
          </div>
          <div class="mb-3">
            <label for="phone" class="form-label">Phone Number</label>
            <vue-tel-input id="phone" v-model="phone"  @blur="handleBlur('phone')"   required mode="international"></vue-tel-input>

            <small v-if="touched.phone && !phone" class="text-danger">Phone is required.</small>
            <small v-else-if="touched.phone && !isPhoneValid" class="text-danger">Phone Number must be at least 10 characters.</small>
          </div>
          <div class="mb-3 col-md-6">
            <label for="lic" class="form-label">LIC</label>
            <input type="text" id="lic" v-model="lic" @blur="touched.lic = true" class="form-control" required />
            <small v-if="touched.lic && !lic" class="text-danger">LIC is required.</small>
          </div>
          <div class="mb-3 col-md-6">
            <label for="npi" class="form-label">NPI</label>
            <input type="text" id="npi" v-model="npi" @blur="touched.npi = true" class="form-control" required />
            <small v-if="touched.npi && !npi" class="text-danger">NPI is required.</small>
            
          </div>
          <div class="mb-3">
            <label for="state" class="form-label">State</label>
            <select v-model="selectedState" @blur="touched.selectedState = true" id="state" class="form-select" required>
              <option value="" disabled>Select a state</option>
              <option v-for="state in states" :key="state.name" :value="state.name">
                {{ state.name }}
              </option>
            </select>
            <small v-if="touched.selectedState && !selectedState" class="text-danger">State is required.</small>

          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Password</label>
            <div class="input-group">
              <input   :type="showPassword ? 'text' : 'password'" id="password" @blur="touched.password = true" v-model="password" class="form-control" required />
              <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0 rounded-right" style="height:100%;">
                  <i @click="toggleShow" class="fas eye-shape"
                    :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                </span>
              </div>
            </div>
            <small v-if="touched.password && !password" class="text-danger">Password is required.</small>
            <small v-else-if="touched.password && password.length < 6" class="text-danger">Password must be at least 6 characters.</small>
            <ul class="mt-4 space-y-2">
      <li class="flex items-center" :class="getRuleClass(passwordRules.length)">
        <span v-if="passwordRules.length"><i class="fa-solid fa-check-double"></i></span>
        <span v-else><i class="fa-solid fa-xmark"></i></span>
        <span class="ml-2"> At least 8 characters</span>
      </li>
      <li class="flex items-center" :class="getRuleClass(passwordRules.uppercase)">
        <span v-if="passwordRules.uppercase"><i class="fa-solid fa-check-double"></i></span>
        <span v-else><i class="fa-solid fa-xmark"></i></span>
        <span class="ml-2"> At least one uppercase letter</span>
      </li>
      <li class="flex items-center" :class="getRuleClass(passwordRules.lowercase)">
        <span v-if="passwordRules.lowercase"><i class="fa-solid fa-check-double"></i></span>
        <span v-else><i class="fa-solid fa-xmark"></i></span>
        <span class="ml-2"> At least one lowercase letter</span>
      </li>
      <li class="flex items-center" :class="getRuleClass(passwordRules.number)">
        <span v-if="passwordRules.number"><i class="fa-solid fa-check-double"></i></span>
        <span v-else><i class="fa-solid fa-xmark"></i></span>
        <span class="ml-2"> At least one number</span>
      </li>
      <li class="flex items-center" :class="getRuleClass(passwordRules.specialChar)">
        <span v-if="passwordRules.specialChar"><i class="fa-solid fa-check-double"></i></span>
        <span v-else><i class="fa-solid fa-xmark"></i></span>
        <span class="ml-2"> At least one special character</span>
      </li>
    </ul>
          </div>

          <div class="mb-3">
            <label for="confirmPassword" class="form-label">Confirm Password </label>
            <div class="input-group">
            <input :type="showPassword ? 'text' : 'password'" id="confirmPassword" v-model="confirmPassword" @blur="touched.confirmPassword = true" class="form-control" />
            <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0 rounded-right" style="height:100%;">
                  <i @click="toggleShow" class="fas eye-shape"
                    :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                </span>
              </div>
            </div>
            <small v-if="touched.confirmPassword && !confirmPassword" class="text-danger">Confirm Password is required.</small>
            <small v-else-if="touched.confirmPassword && confirmPassword !== password" class="text-danger">Passwords do not match.</small>
          </div>

          <div class="mb-3">
            <label for="clinicCode" class="form-label">Clinic Code</label>
            <input type="text" id="clinicCode" v-model="clinicCode" @blur="touched.clinicCode = true" class="form-control"  required />
            <small v-if="touched.clinicCode && !clinicCode" class="text-danger">Clinic Code is required.</small>
          </div>
          <div class="mb-3">
            <label for="specialty" class="form-label">Select your specialty</label>
            <select v-model="selectedSpecialty"  @blur="touched.selectedSpecialty = true" id="specialty" class="form-select" required>
              <option value="" disabled>Select a specialty</option>
              <option v-for="specialty in specialties" :key="specialty.id" :value="specialty.id">
                {{ specialty.name }}
              </option>
            </select>
            <small v-if="touched.selectedSpecialty && !selectedSpecialty" class="text-danger">Specialty is required.</small>

          </div>
          <div class="mb-3">
            <a href="https://ensofia.com/terms-of-use/" target="_blank" rel="noopener noreferrer">
              By registering, you agree to our terms and conditions (Here)
            </a>
          </div>
          <button type="submit" class="btn btn-primary w-100" :disabled="!isFormValid || loading">Register</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import states from '@/assets/states.json';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';


const firstName = ref('');
const lastName = ref('');
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const passwordRules = computed(() => ({
  length: password.value.length >= 8,
  uppercase: /[A-Z]/.test(password.value),
  lowercase: /[a-z]/.test(password.value),
  number: /[0-9]/.test(password.value),
  specialChar: /[\W_]/.test(password.value),
}));
const phone = ref('');
const lic = ref('');
const npi = ref('');
const touched = ref({
  firstName: false,
  lastName: false,
  email: false,
  password: false,
  confirmPassword: false,
  phone:false,
  lic:false,
  npi:false,
  clinicCode:false,
  selectedSpecialty:false,
  selectedState:false,
});
const clinicCode = ref('' || 61145) ;
const selectedSpecialty = ref('');
const specialties = ref([]);
const selectedState = ref('');
const loading = ref(false);
const toast = useToast();
const router = useRouter();
const store = useStore()
let showPassword = ref(false);

const fetchSpecialties = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_SOFA_API}getSpecialtyList`);
    specialties.value = response.data;
  } catch (error) {
      toast.error('Error fetching specialties:', error);
      console.error('Error fetching specialties:', error);
  }
};

onMounted(() => {
  fetchSpecialties();
});
const toggleShow = () => {
            showPassword.value = !showPassword.value;
        };

const isFormValid = computed(() => {
  return firstName.value && lastName.value && isEmailValid.value &&  isPasswordValid.value &&
  password.value === confirmPassword.value && isPhoneValid.value && lic.value && npi.value && selectedState.value && clinicCode.value && selectedSpecialty.value;
});
const isEmailValid = computed(() => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email.value);
});

const isPasswordValid = computed(() => Object.values(passwordRules.value).every(Boolean));

const getRuleClass = (rule) => rule ? 'text-success' : 'text-danger';

const isPhoneValid = computed(() => phone.value && phone.value.length >= 10);

const handleBlur = (field) => {
  touched.value[field] = true;
};

const handleRegister = async () => {
  loading.value = true;
  try {
    const response = await axios.post(`${process.env.VUE_APP_SOFA_API}register`, {
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      password: password.value,
      phone: phone.value,
      lic: lic.value,
      npl: npi.value,
      clinic_code: clinicCode.value,
      specialty_id: selectedSpecialty.value,
      state: selectedState.value,
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
    console.log(response.data);
    store.commit('setPhone', response.data.responseData.phone);
    router.push('/otp');
  } catch (error) {
    const statusCode = error.response?.status;
    if (statusCode === 409) {
      error.value("The account already exists");
      toast.error("The account already exists");
    } else if (statusCode === 201 ||statusCode === 200) {
      console.log("User created successfully");
      toast.success("User created successfully");
    } else {
      error.value(`Server error ${statusCode}`);
      toast.error(`Server error ${statusCode}`);
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.register {
  max-width: 400px;
  margin: 0 auto;
  background: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

a {
  text-decoration: none;
  color: #518DB1;
  font-weight: bold;
}
ul{
  list-style-type: none;
}
</style>
