<template>
    <div class="d-flex justify-content-around justify-content-lg-center nav-header navfix col-lg-10">
        <div class="p-3 text-white toggle-btn d-block d-lg-none" @click="toggleSidebar" >
            <i class="fa-solid fa-bars"></i>
        </div>
        <div class="my-3 ms-auto" v-if="patientName">
            <h5>Patient name: <span>{{ patientName }}</span></h5>
        </div>
        <div class="d-lg-flex align-items-center ms-auto  d-none">
          <div class="dropdown">
            <button 
              class="user-circle dropdown-toggle me-4 d-none d-lg-block" 
              type="button" 
              id="userDropdown" 
              data-bs-toggle="dropdown" 
              aria-expanded="false">
              {{ username.charAt(0).toUpperCase() }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
              <li  v-for="item in lowerNavItems" :key="item.name">
                    <a v-if="item.path.startsWith('http')" :href="item.path" target="_blank" rel="noopener noreferrer" class="dropdown-item">
                        <i :class="item.icon"></i> <span v-if="!isCollapsed"> {{ item.name }}</span>
                    <router-link  v-else class="dropdown-item" :to="item.path">
                      <i :class="item.icon"></i>
                    </router-link>
                  </a>
                </li>
                <li class="dropdown-item text-danger" @click="logout">
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>
                    <span> Logout</span>
                </li>
          </ul>
        </div>
      </div>
        <img v-if="!patientName" class=" d-block d-lg-none" src="./../assets/logo_sofia.png" alt="">
        <router-link :to="`/addEnconter`" class="text-decoration-none" >
        <button  class="add-topic-btn my-3 btn btn-primary d-block d-lg-none">+ </button>
        </router-link>
    </div>
    <div>
        <SideNav v-if="isCollapsed" class="sideNav fixed-top"/>
    </div>
</template>
<script setup>
import { ref , } from 'vue';
import SideNav from './sideNav.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';


const router = useRouter();
const store = useStore();


const username = localStorage.getItem('username') ||ref("");

// eslint-disable-next-line no-undef
defineProps({
  patientName: {
    type: String,
    required: true
  }
});
const isCollapsed = ref(false);

const toggleSidebar = () => {
  isCollapsed.value = !isCollapsed.value;
};
const lowerNavItems = [
        { name: 'Help', path: 'https://ensofia.com/', icon: 'fa-regular fa-circle-question' },
        { name: 'Contact', path: 'https://ensofia.com/contact-us/', icon: 'fa-solid fa-mobile-screen-button' },
    ];
    const logout = async () => {
    try {
        await store.dispatch('auth/logout');
        router.push('/login'); 
    } catch (error) {
        console.error('Logout failed:', error);
    }
    };
</script>
<style>
li{
  cursor: pointer;
}
.navfix{
  position: fixed;
  top: 0;
  /* width: 80%; */
  z-index: 1050;

}
.nav-header {
 /* background-color: #518DB1; */
  left: unset;  
  right: unset;
  height: 60px;
  
}
.toggle-btn {
    text-align: center;
    cursor: pointer;
}
.fixed-top{
  left: unset !important; 
}

.sideNav{
    width: 90%;
    background-color: #f9f9f9;
}
.sidebar-collapsed .nav-link span {
    display: none;
}
.user-circle {
  width: 40px;
  height: 40px;
  background-color: #6fc8ef;
  color: white;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s;
  border:none;
}

.user-circle:hover {
  background-color: #66b9dd; 
}
@media (max-width: 992px) {
  .nav-header {
    width: 100%;
    justify-content: space-between;
    z-index: 1;
  }
.fixed-top{
  left: 0 !important;
  }
  }
</style>