<template>
    <div class=" d-flex flex-column sidebar"  :class="{'sidebar-collapsed': isCollapsed}">
        
        <div class="nav-upper flex-grow-1 overflow-auto text-center">
            <div class="d-flex justify-content-around nav-header mt-4">
            <a href="/" style="width: 100%;padding: 0 15px;">
                <img  class="logo" src="./../assets/logo_sofia.png" alt="" >
            </a>
            <div class="d-flex align-items-center ">
          <div class="dropdown">
            <button 
              class="btn dropdown-toggle me-4 d-lg-none d-block" 
              type="button" 
              id="userDropdown" 
              data-bs-toggle="dropdown" 
              aria-expanded="false">
              {{ username }}
            </button>
            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
              <li  v-for="item in lowerNavItems" :key="item.name">
                    <a v-if="item.path.startsWith('http')" :href="item.path" target="_blank" rel="noopener noreferrer" class="dropdown-item">
                        <i :class="item.icon"></i> <span v-if="!isCollapsed"> {{ item.name }}</span>
                        <router-link  v-else class="dropdown-item" :to="item.path">
                            <i :class="item.icon"></i>
                        </router-link>
                    </a>
                </li>
                <li class="dropdown-item text-danger" @click="logout">
                    <i class="fa-solid fa-arrow-right-from-bracket"></i>
                    <span> Logout</span>
                </li>
            </ul>
        </div>
            </div>
            <div class="p-3 text-white toggle-btn d-block d-lg-none" @click="toggleSidebar">
                <i class="fas fa-times"></i>
            </div>
        </div>
            <EncountersList  />
            <p><small class="text-dark ">V {{appVersion}}</small></p>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import EncountersList from "@/pages/encountersList.vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { version as appVersion } from '../../package.json';

const router = useRouter();
const store = useStore();

const username = localStorage.getItem('username');
const logout = async () => {
    try {
        await store.dispatch('auth/logout');
        router.push('/login'); 
    } catch (error) {
        console.error('Logout failed:', error);
    }
    };

    const isCollapsed = ref(false);
    const toggleSidebar = () => {  
        isCollapsed.value = !isCollapsed.value;
    };

    const lowerNavItems = [
        { name: 'Help', path: 'https://ensofia.com/', icon: 'fa-regular fa-circle-question' },
        { name: 'Contact', path: 'https://ensofia.com/contact-us/', icon: 'fa-solid fa-mobile-screen-button' },
    ];
</script>

<style scoped>
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 18%; 
    height: 100vh;
    background: #f8f9fa;
}
li{
    cursor:pointer;
}
.sidebar-collapsed {
    display: none;
}
.logo{
    width: 150px;
    height: 60px;
}
.nav-header{
    /* background-color: #518DB1; */
}
.toggle-btn {
    text-align: center;
    cursor: pointer;
}

.nav-link i {
    margin-right: 10px;
}
.sidebar-collapsed .nav-link span {
    display: none;
}
.nav-upper {
    overflow-y: scroll; 
    scrollbar-width: thin;
    scrollbar-color: #518DB1 #f1f1f1;
}

/* Custom scrollbar styles for WebKit browsers */
.nav-upper::-webkit-scrollbar {
    width: 12px; 
}

.nav-upper::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.nav-upper::-webkit-scrollbar-thumb {
    background-color: #518DB1; 
    border-radius: 10px; 
    border: 3px solid #f1f1f1; 
}

.nav-upper::-webkit-scrollbar-thumb:hover {
    background-color: #518DB1; 
}

@media (max-width: 992px) {
    .sidebar-collapsed {
        display: none !important;
    }
    .nav-lower {
        flex-direction: row;
    }
    .nav-lower .nav-link {
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    }
    .sidebar {
        width: unset; 
    }
}
</style>
