<template>
  <div class="container d-flex justify-content-center align-items-center min-vh-100">
    <div class="card p-4" style="max-width: 400px; width: 100%;">
      <div class="text-center mb-4">
        <img src="./../../assets/logo_sofia.png" alt="Logo" class="img-fluid">
      </div>
      <form @submit.prevent="login">
        <div class="mb-3">
          <label for="email" class="form-label">Email</label>
          <input v-model="email" type="email" id="email" class="form-control" placeholder="Enter your email" required
            @blur="touched.email = true" />
          <small v-if="touched.email && !email" class="text-danger">Email is required.</small>
          <small v-else-if="touched.email && !isEmailValid" class="text-danger">Please enter a valid email
            address.</small>
        </div>

        <div class="mb-3">
          <label for="password" class="form-label">Password</label>
          <div class="input-group">
            <input v-model="password" :type="showPassword ? 'text' : 'password'" id="password" class="form-control"
              placeholder="Enter your password" required @blur="touched.password = true" />
            <div class="input-group-append">
              <span class="input-group-text text-muted bg-transparent border-left-0 rounded-right" style="height:100%;">
                <i @click="toggleShow" class="fas eye-shape"
                  :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
              </span>
            </div>
          </div>
          <small v-if="touched.password && !password" class="text-danger">Password is required.</small>
          <small v-else-if="touched.password && password.length < 6" class="text-danger">Password must be at least 6
            characters.</small>

        </div>

        <div class=" d-flex flex-column mb-3">
          <button type="submit" class="btn btn-primary mb-2" :disabled="!isFormValid || loading">Login</button>
          <button class="btn btn-primary" @click="sfhirLogin">Login with EPIC</button>

        </div>

        <a href="/forget-password" class="link-primary text-center">
          <p>Forget your password?</p>
        </a>

        <p class="text-center">Don't have an account? <a href="/register" class="link-primary">Signup</a></p>
      </form>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';

const email = ref('');
const password = ref('');
const touched = ref({
  email: false,
  password: false,
});
const error = ref('');
const router = useRouter();
const store = useStore();
const loading = ref(false);
const toast = useToast();
let showPassword = ref(false);

const sfhirLogin = async () => {
  try {
    console.log("sfhirLogin started...");
    const username = 'integrator@ensofia.com';
    const password = 'AnalYtics$2420';
    const basicAuth = btoa(`${username}:${password}`);

    const response = await fetch(`${process.env.VUE_APP_SOFA_API}sfhir_login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Basic ${basicAuth}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to login. Status: ${response.status}`);
    }

    const responseData = await response.json();
    const sfhirToken = responseData.access_token;
    localStorage.setItem('sfhirToken', sfhirToken);
    if (!responseData.access_token) {
      throw new Error('Invalid response: Access token missing.');
    }

    console.log("Login successful, fetching patient data...");
    console.log(sfhirToken)
    getOrgData(sfhirToken)
  } catch (error) {
    console.error("sfhirLogin Error:", error.message);
  }
};
const getOrgData = async (sfhirToken) => {
  try {
    console.log("getOrgData...");
    const response = await fetch(`${process.env.VUE_APP_SOFA_API}get_ehr_organization_config`, {
      method: 'Post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sfhirToken}`,
      },
    });

    if (!response.ok) {
      throw new Error(`getOrgData. Status: ${response.status}`);
    }

    const responseData = await response.json();
    console.log(responseData)
    const clientId = responseData.clientId
    const redirectUri = responseData.redirectUri
    const organizationName = responseData.organizationName
    const scope = responseData.scope
    const aud = responseData.aud
    const authorizationUrl = responseData.authorizationUrl
    const tokenUrl = responseData.tokenUrl
    console.log("getOrgData successful");
    generateUrl(clientId, redirectUri, organizationName, scope, aud, authorizationUrl, tokenUrl)
  } catch (error) {
    console.error("getOrgData Error:", error.message);
  }
};
const generateUrl = async (clientId, redirectUri, organizationName, scope, aud, authorizationUrl, tokenUrl) => {

  console.log("generateUrl...");
  window.open(`${authorizationUrl}?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&$scope=${scope}&aud=${aud?.slice(0, -1)}`);
};

onMounted(() => {

});

const isFormValid = computed(() => {
  return isEmailValid.value && password.value.length >= 6
});
const isEmailValid = computed(() => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email.value);
});
const toggleShow = () => {
  showPassword.value = !showPassword.value;
};
const login = async () => {
  loading.value = true;
  error.value = '';

  try {
    await store.dispatch('auth/login', {
      email: email.value,
      password: password.value,
    });
    router.push('/');
  } catch (err) {
    if (err.response) {
      const statusCode = err.response.status;
      const message = err.response.data.message;
      if (statusCode === 401 && message == "user exists but not active") {

        router.push('/otp');
      } else if (statusCode === 400) {
        error.value = 'Please try again later.';
        toast.error("Please try again later.");
      } else if (statusCode === 401) {
        error.value = 'The password or email is incorrect.';
        toast.error("The password or email is incorrect.");
      } else {
        error.value = `Server error ${statusCode}`;
        toast.error(`Server error ${statusCode}`);

      }
    } else {
      error.value = 'Please try again later.';
    }
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped>
.card {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.form-control {
  border-radius: 4px;
}

.btn-primary {
  background-color: #518DB1;
  border-color: #518DB1;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.link-primary {
  color: #518DB1;
}

.link-primary:hover {
  color: #0056b3;
}
</style>
