<template>
  <div id="app">
    <main>
      <div class="row m-0 "  v-if="isAuthenticated">
        <div v-if="isAuthenticated" class="col-lg-2 p-0 d-none d-lg-block position-relative">
          <SideNav class=""/>
        </div>
        <div class="col-12 col-lg-10 p-0">
          <router-view />
        </div>
      </div>
      <div v-else class="col-12 col-lg-12">
          <router-view />
        </div>
    </main>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SideNav from '../src/layouts/sideNav.vue'
import { useConnectionStatus } from './utils/useConnectionStatus';

const store = useStore();
const isAuthenticated = computed(() => store.getters['auth/isAuthenticated']);

useConnectionStatus();
</script>

<style>
/* Global styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9 !important;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  min-height: 100vh;
}

</style>
