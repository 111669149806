
import axios from 'axios';

export default {
    namespaced: true,
    state: () => ({
        isAuthenticated: !!localStorage.getItem('token'),
    //   userRole: null, 
          token: localStorage.getItem('token') || null,
          refreshToken: localStorage.getItem('refreshToken') || null,
          username: localStorage.getItem('username') || null,
    }),
    mutations: {
      SET_AUTHENTICATED(state, value) {
        state.isAuthenticated = value; 
      },
    //   SET_USER_ROLE(state, role) {
    //     state.userRole = role;
    //   },
    SET_TOKENS(state, { token, refreshToken }) {
        state.token = token;
        state.refreshToken = refreshToken;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
      },
      SET_USERNAME(state, { username }) {
        state.username = username;
        localStorage.setItem('username', username);
      },
      LOGOUT(state) {
        state.isAuthenticated = false;
        // state.userRole = null;
        state.token = null;
        state.refreshToken = null;
        state.username = null;
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('username');
      }
    },
    actions: {
    async login({ commit }, { email, password }) {
        const auth = `Basic ${btoa(`${email}:${password}`)}`;
          const response = await axios.post(`${process.env.VUE_APP_SOFA_API}login`, {
            email,
            password,
          }, {
            headers: {
              'Authorization': auth,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': 'Access-Control-Allow-Origin, Accept',
              'Access-Control-Allow-Credentials': true,
              'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE, HEAD',
            },
          });
  console.log(response.data.responseData)
          const { token, refresh_token, } = response.data.responseData;
          commit('SET_TOKENS', { token, refreshToken: refresh_token });
          commit('SET_AUTHENTICATED', true);
          commit('SET_USERNAME', { username:response.data.responseData.username });

        
      },
      async handleTwoFa({ commit }, { code, phone,from }) {
            const response = await axios.post(`${process.env.VUE_APP_SOFA_API}2fa`, {
              code,
              phone,
            }, {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
            });
          console.log(from)
          const { token, refresh_token } = response.data.responseData;
          if (from !== 'forget-password') {

          commit('SET_TOKENS', { token, refreshToken: refresh_token });
          commit('SET_AUTHENTICATED', true);
          }
          return { token };
        
      },
      logout({ commit }) {
        commit('LOGOUT');
      },
      // setRole({ commit }, role) {
      //    commit('SET_USER_ROLE', role);
      // }
    },
    getters: {
      isAuthenticated: (state) => state.isAuthenticated,
    //   userRole: (state) => state.userRole,
      token: (state) => state.token,
      refreshToken: (state) => state.refreshToken,
    },
  };
  