import { ref } from "vue";

export function useRecorder(language) {
    const transcripts = ref([]);
    const isRecording = ref(false);
    const errorMessage = ref("");
    const combinedTranscript = ref(""); 
    let websocket = null;
    let reconnectTimeout = null; 

    console.log(language);

    const startStreaming = () => {
        errorMessage.value = "";
        transcripts.value = [];
        combinedTranscript.value = "";
        isRecording.value = true;

        connectWebSocket();
    };

    const connectWebSocket = () => {
        websocket = new WebSocket("wss://ai-stage.ensofia.app:8443/fast/stream_ws");
        websocket.onopen = () => {
            console.log("WebSocket connection established.");
            startRecording();
        };

        websocket.onmessage = (event) => {
            const data = JSON.parse(event.data);

            const last = transcripts.value[transcripts.value.length - 1];
            if (last && last.transcript === data.transcript && last.isFinal === data.is_final) {
                return;
                }

                if (data.is_final) {
                transcripts.value.push(data.transcript);
                combinedTranscript.value = transcripts.value.join(" "); 
                console.log(combinedTranscript.value)
            }
        };

        websocket.onerror = (error) => {
            console.error("WebSocket error:", error);
            errorMessage.value = "An error occurred with the WebSocket connection.";
            stopStreaming();
        };

        websocket.onclose = (event) => {
            console.log("WebSocket connection closed:", event);

            if (isRecording.value) {
                reconnectTimeout = setTimeout(() => {
                console.log("Reconnecting WebSocket...");
                connectWebSocket();
                }, 500);
            }
        
            stopRecording();
        };
    };
    const stopStreaming = () => {
        if (websocket) {
            console.log("Stopping WebSocket streaming connection...");
            setTimeout(() => {
                if (websocket) {
                websocket.close();
                websocket = null;
                
                isRecording.value = false;
                console.log("WebSocket streaming connection closed after delay.");
                    }
                }, 40000); 
            }
        
            if (reconnectTimeout) {
                clearTimeout(reconnectTimeout);
                reconnectTimeout = null;
            }
    };

    let mediaRecorder;
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const startRecording = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const source = audioContext.createMediaStreamSource(stream);
        const processor = audioContext.createScriptProcessor(4096, 1, 1);

        source.connect(processor);
        processor.connect(audioContext.destination);

        processor.onaudioprocess = (event) => {
            if (websocket && websocket.readyState === WebSocket.OPEN) {
                const audioData = event.inputBuffer.getChannelData(0);
                const audioBuffer = new Int16Array(audioData.length);
                for (let i = 0; i < audioData.length; i++) {
                    audioBuffer[i] = audioData[i] * 0x7fff; 
                }
                websocket.send(audioBuffer.buffer);
            }
        };

        mediaRecorder = { stream, source, processor };
        } catch (err) {
            console.error("Error accessing microphone:", err);
            errorMessage.value = "Could not access the microphone.";
            stopStreaming();
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stream.getTracks().forEach((track) => track.stop());
            mediaRecorder.processor.disconnect();
            mediaRecorder.source.disconnect();
            mediaRecorder = null;
        }
    };

    return {
        isRecording,
        transcription: combinedTranscript,
        errorMessage,
        startRecording: startStreaming,
        stopRecording: stopStreaming,
    };
}
